<template>
  <div>
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg11 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Novo pedido</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <pedido-form></pedido-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import PedidoForm from "@/components/pedido/cadastroPedido.vue"
export default {
  components: {
    PedidoForm
  },
  data: () => ({}),
  created() {}
}
</script>
