<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            style="width:250px"
            class="mr-2"
            label="Frete"
            name="tipoFrete"
            v-model="pedido.tipoFrete"
            required
          ></v-text-field>
          <money style="max-width:110px" class="mr-2" v-model="pedido.valorFrete" v-bind="money"></money>
          <v-menu
            class="mr-2"
            ref="dataCompra"
            lazy
            :close-on-content-click="false"
            v-model="dataCompra"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataCompraMercadoria"
          >
            <v-text-field
              slot="activator"
              label="Data compra"
              v-model="dataCompraMercadoria"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataCompraMercadoria" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataCompra = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dataCompra.save(dataCompraMercadoria)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="mr-2"
            ref="dataEntrega"
            lazy
            :close-on-content-click="false"
            v-model="dataEntrega"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataEntregaEmEstoque"
          >
            <v-text-field
              slot="activator"
              label="Data entrega"
              v-model="dataEntregaEmEstoque"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataEntregaEmEstoque" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataEntrega = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dataEntrega.save(dataEntregaEmEstoque)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            class="mr-4"
            label="Valor do pedido"
            name="valorPedido"
            v-model="totalPedido"
            required
            readonly
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg9 sm12 xs12>
        <div class="d-flex">
          <select-fornecedor class="mr-2"></select-fornecedor>
        </div>
      </v-flex>
      <v-flex lg3 sm12 xs12>
        <div class="d-flex center">
          <v-btn class="mr-2" depressed dark color="primary" @click="adicionarProdutos">
            Adicionar produto
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headersProduto" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>
                <v-text-field
                  style="width:80px"
                  label="Referência"
                  name="referencia"
                  v-model="props.item.referencia"
                  required
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  class="mr-2"
                  label="Nome do produto"
                  name="nomeProduto"
                  v-model="props.item.nomeProduto"
                  required
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidade"
                  required
                ></v-text-field>
              </td>
              <td>
                <money style="width:80px" class="mr-2" v-model="props.item.precoCompra" v-bind="money"></money>
              </td>
              <td>{{ (props.item.total = props.item.quantidade * props.item.precoCompra).toLocaleString() }}</td>
              <td>
                <v-text-field
                  class="mr-2"
                  label="Grade (Formato dos dados: P:10; M:10; G:10)"
                  name="grade"
                  v-model="props.item.grade"
                  required
                  title="Formato dos dados: P:10; M:10; G:10"
                ></v-text-field>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-select
            class="mr-2"
            :items="formasPagamento"
            item-value="Id"
            item-text="FormaPagamento"
            label="Forma de pagamento"
            v-model="idFormaPagamento"
            :rules="formaPagamentoRule"
            return-object
          ></v-select>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <money class="mr-2" v-model="valorPagamento" v-bind="money"></money>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevPagamento"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataPagamento"
          >
            <v-text-field
              slot="activator"
              label="Data pagamento"
              v-model="dataPagamento"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataPagamento" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevPagamento = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataPagamento)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn max-width="20px" depressed icon dark color="primary" small @click="adicionarPagamento">
            <v-icon small>library_add</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <v-data-table :headers="headersPagamento" :items="pagamentos" hide-actions class="elevation-0">
          <template v-slot:items="props">
            <td style="display:none;">{{ props.item.IdFormaPagamento }}</td>
            <td>{{ props.item.ValorPagoString }}</td>
            <td>{{ props.item.FormaPagamento }}</td>
            <td>{{ props.item.DataPagamento }}</td>
            <td>
              <v-btn max-width="15px" depressed icon dark color="primary" small @click="removerPagamento(props.index)">
                <v-icon small>remove_circle_outline</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn @click="voltar" outline color="primary">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { Money } from "v-money"
import Pedido from "../../domain/pedido/Pedido"
import ProdutoPedido from "../../domain/pedido/ProdutoPedido"
import FormaPagamento from "../../domain/saida/FormaPagamento"
import SelectFornecedor from "../fornecedor/selectFornecedor.vue"
import ProgressCircular from "../progressCircular/progressCircular"
import Pagamento from "../../domain/saida/Pagamento"
import { ServicoPedido } from "../../servicos/servicoPedido"
const servicoPedido = new ServicoPedido()
export default {
  components: {
    SelectFornecedor,
    ProgressCircular,
    Money
  },
  data() {
    return {
      id: null,
      valid: false,
      dataEntrega: false,
      dataCompra: false,
      dtPrevPagamento: false,
      dataPagamento: null,
      dataEntregaEmEstoque: null,
      dataCompraMercadoria: null,
      pedido: new Pedido(),
      valorPagamento: null,
      idFormaPagamento: null,
      formasPagamento: [],
      formaPagamentoRule: [v => !!v || "Campo obrigatório"],
      pagamentos: [],
      pagamento: new Pagamento(),
      produtos: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " # ",
        precision: 2,
        masked: false
      },
      headersProduto: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Nome do produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "Preço un.", align: "left", sortable: false, value: "precoCompra" },
        { text: "Total", align: "left", value: "total", sortable: false },
        { text: "Grade", align: "left", sortable: false }
      ],
      headersPagamento: [
        { text: "Valor", align: "left", sortable: false, value: "ValorPago" },
        { text: "Forma de pagamento", align: "left", sortable: false, value: "FormaPagamento" },
        { text: "Data", align: "left", sortable: false, value: "DataPagamento" },
        { text: "", align: "left", sortable: false }
      ],
      headersGrade: [
        { text: "Tamanho", align: "left", sortable: false, value: "tamanho" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "", align: "left", sortable: false }
      ]
    }
  },
  created() {
    this.getFormasPagamento()
    serverBus.$on("fornecedorSelected", value => {
      this.pedido.idFornecedor = value
    })
  },
  computed: {
    totalPedido: function() {
      var total = 0
      this.produtos.forEach(prod => {
        total += prod.quantidade * prod.precoCompra
      })
      return total.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
    }
  },
  methods: {
    adicionarProdutos() {
      this.produtos.push(new ProdutoPedido())
    },
    adicionarPagamento() {
      this.pagamento.ValorPago = this.valorPagamento
      this.pagamento.IdFormaPagamento = parseInt(this.idFormaPagamento.Id)
      this.pagamento.FormaPagamento = this.idFormaPagamento.FormaPagamento
      this.pagamento.DataPagamento = this.dataPagamento
      var novoPagamento = new Pagamento()

      this.valorPagamento = 0
      this.dataPagamento = null

      novoPagamento.IdFormaPagamento = this.pagamento.IdFormaPagamento
      novoPagamento.DataPagamento = this.pagamento.DataPagamento
      novoPagamento.FormaPagamento = this.pagamento.FormaPagamento
      novoPagamento.ValorPago = this.pagamento.ValorPago
      novoPagamento.ValorPagoString = novoPagamento.ValorPago.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      })

      this.pagamentos.push(novoPagamento)
    },
    getFormasPagamento() {
      var dinheiro = new FormaPagamento()
      dinheiro.Id = 1
      dinheiro.FormaPagamento = "Dinheiro"
      var cartao = new FormaPagamento()
      cartao.Id = 2
      cartao.FormaPagamento = "Cartão"
      var deposito = new FormaPagamento()
      deposito.Id = 3
      deposito.FormaPagamento = "Depósito"
      var transf = new FormaPagamento()
      transf.Id = 4
      transf.FormaPagamento = "Transferência"
      var cheque = new FormaPagamento()
      cheque.Id = 5
      cheque.FormaPagamento = "Cheque"
      this.formasPagamento.push(dinheiro)
      this.formasPagamento.push(cartao)
      this.formasPagamento.push(deposito)
      this.formasPagamento.push(transf)
      this.formasPagamento.push(cheque)
    },
    removerPagamento(index) {
      this.pagamentos.splice(index, 1)
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "Pedidos" })
    },
    atualzarTotalItem(prod) {
      if (
        prod.quantidade != undefined &&
        prod.quantidade != "" &&
        prod.precoCompra != undefined &&
        prod.precoCompra != 0
      ) {
        prod.total = prod.quantidade * prod.precoCompra
        // eslint-disable-next-line
        console.log(prod.total)
      }
    },
    atualizaTotal() {
      this.pedido.valorPedido = 0
      this.produtos.forEach(prod => this.calcularTotal(prod))
      this.pedido.valorPedido = this.pedido.valorPedido.toLocaleString()
    },
    calcularTotal(prod) {
      if (
        prod.quantidade != undefined &&
        prod.quantidade != "" &&
        prod.precoCompra != undefined &&
        prod.precoCompra != 0
      ) {
        this.pedido.valorPedido += prod.quantidade * prod.precoCompra
      }
    },
    submit() {
      this.abrirProgressCircular()
      this.pedido.id = 0
      this.pedido.dataDoPedido = this.dataCompraMercadoria
      this.pedido.dataPrevistaEntrega = this.dataEntregaEmEstoque
      this.pedido.valorPedido = parseFloat(this.totalPedido.replace(/\D/g, ""))

      this.produtos.forEach(prod => (prod.quantidade = parseInt(prod.quantidade)))
      servicoPedido.salvar(this.pedido, this.produtos, this.pagamentos).then(
        res => {
          this.fecharProgressCircular()
          if (res.status === 200) {
            this.voltar()
          }
        },
        // eslint-disable-next-line
        err => console.log(err)
      )
    }
  }
}
</script>
