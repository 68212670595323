export default class Pedido {
  constructor(
    id = "",
    idFornecedor = "",
    dataDoPedido = "",
    dataPrevistaEntrega = "",
    valorPedido = "",
    valorFrete = "",
    tipoFrete = ""
  ) {
    ;(this.id = id),
      (this.idFornecedor = idFornecedor),
      (this.dataDoPedido = dataDoPedido),
      (this.dataPrevistaEntrega = dataPrevistaEntrega),
      (this.valorPedido = valorPedido),
      (this.valorFrete = valorFrete),
      (this.tipoFrete = tipoFrete)
  }
}
